import LoginWrapper from 'Pages/LoginWrapper';
// import redirectLoggedIn from '../services/auth/redirectLoggedIn';

export default function Login() {
   return <LoginWrapper />;
}

export async function getServerSideProps() {
   return {
      props: {
         initialReduxState: {
            site: JSON.parse(process.env.NEXT_PUBLIC_STORE_INFO)[process.env.NEXT_PUBLIC_STORE_ID],
         },
      },
   };
}
