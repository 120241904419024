import { useEffect } from 'react';

// hook para mostrar error en el input de un formulario
// se utiliza el metodo setError de react-hook-form
// formRef     -> es una referencia al formulario, asi podemos acceder al setError
// error       -> es un objeto { type: String, message: String }
// onUnmount:  -> es un callback que podemos llamar cuando se desmonta el componente

const useFormError = (formRef, errors, onUnmount = null) => {
   // ejecutar la funcion cuando el componente hace unmount
   useEffect(() => {
      return () => {
         onUnmount();
      };
   }, []);

   // mostramos el error en el input cuando el parametro error cambia de valor
   // error.type hace referencia al name del input en el formulario
   // setError(inputName, config)
   useEffect(() => {
      if (errors && formRef.current) {
         errors.forEach((x) => {
            if (x.error_type !== 'general') {
               formRef.current.setError(x.error_type, {
                  type: 'manual',
                  message: x.error_description,
               });
            }
         });
      }
   }, [errors]);
};

export default useFormError;
