/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import Input from 'Form/Input';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const PasswordInput = (props) => {
   const [visible, setvisible] = useState(false);

   const togglePasswordVisiblity = () => {
      setvisible(!visible);
   };

   return (
      <>
         <Input {...props} type={visible ? 'text' : 'password'} />
         <div
            onClick={togglePasswordVisiblity}
            style={{
               position: 'relative',
               width: 0,
               top: '40px',
               right: '40px',
            }}
         >
            {visible ? (
               <MdVisibilityOff
                  size="1.5rem"
                  style={{
                     color: '#878887',
                  }}
               />
            ) : (
               <MdVisibility
                  size="1.5rem"
                  style={{
                     color: '#878887',
                  }}
               />
            )}
         </div>
      </>
   );
};

export default PasswordInput;
