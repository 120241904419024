/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useRef } from 'react';
import Button from 'Button';
import Input from 'Form/Input';
import PasswordInput from 'Form/PasswordInput';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../domain/Layout';
import useFormError from '../../hooks/useFormError';
import { clearError, login, setEmailCheckout, setLoggedUser, resetUsers } from '../../redux';
import { AUTH_SUCCESS } from '../../redux/auth/actionTypes';
import sentry from '../../services/sentry';
import styles from '../../styles/pages/login.module.scss';

const Form = dynamic(() => import('Form'));

export default function LoginWrapper() {
   const formRef = useRef(null);
   const dispatch = useDispatch();
   const router = useRouter();
   const { loading, errors } = useSelector((state) => state.auth);
   const { noLogin, email, noLoginPrevious, isFromCheckout } = useSelector(
      (state) => state.checkout,
   );

   // hook para mostrar errores en los input
   // al hacer unmount reseteamos el error a null
   useFormError(formRef, errors, () => {
      dispatch(clearError());
   });

   const renderButton = () => {
      if (!noLogin && !noLoginPrevious) {
         return (
            <Button
               label="Crear cuenta"
               variant="secondary"
               onClick={() => router.push('/signup')}
               normal
            />
         );
      }
   };

   // si el usuario ya ha logueado anteriormente,
   // mostramos el ultimo email ingresado
   const verifyLastLogin = () => {
      let user = localStorage.getItem('user');

      if (user) {
         user = JSON.parse(user);
         formRef.current.setValue('email', user.email);
      }
   };

   const handleSubmit = async ({ email, password }) => {
      email = email.toLowerCase();
      const result = await dispatch(login(email, password));

      if (result.type === AUTH_SUCCESS) {
         await dispatch(resetUsers());
         dispatch(setEmailCheckout(email));
         dispatch(setLoggedUser());
         // Guardamos en Sentry que usuario esta logueado
         sentry.setUser({ email });
         switch (true) {
            case isFromCheckout:
               router.push('/orders');
               break;
            case noLogin:
            case noLoginPrevious:
               router.push('/checkout/personal-info');
               break;
            default:
               router.push('/');
         }
      }
   };

   return (
      <Layout
         title="Iniciar sesion"
         description="Accede a tu cuenta en Vaypol y disfruta de una experiencia personalizada. Ingresa para explorar nuestras ofertas y servicios. ¡Bienvenido de nuevo a Vaypol!"
         headerLite
      >
         <div className={styles.container}>
            <Form
               getRef={(ref) => {
                  formRef.current = ref.current;
                  verifyLastLogin();
               }}
               onSubmit={handleSubmit}
               className={styles.form}
            >
               <hgroup className={styles.header}>
                  <h1 className={styles.titleSecondary}>Ingresá a tu Cuenta</h1>
                  <p className={styles.subtitleSecondary}>
                     Ingresá tu email y contraseña para entrar a tu cuenta.
                  </p>
               </hgroup>

               <Input name="email" type="email" label="Email" autocomplete={email} required />
               <PasswordInput
                  iconRight
                  name="password"
                  type="password"
                  label="Contraseña"
                  autocomplete="current-password"
                  placeholder="Más de 6 caracteres"
                  required
               />

               <div className={styles.link}>
                  <Link href="/forgot-password">
                     <a>¿Olvidaste la contraseña?</a>
                  </Link>
               </div>

               <div className={styles.buttons}>
                  <Button
                     id="iniciar_sesión_LoginWrapper"
                     label="Iniciar sesión"
                     className={styles.button}
                     loading={loading}
                  />

                  {renderButton()}
               </div>
            </Form>
         </div>
      </Layout>
   );
}
